import { useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { useReportDimensions } from '../../../../hooks/useReportDimensions';
import ReportActionRow from '../ReportActionRow';
import AggregatorMonthlyDoc from '../pdf-documents/AggregatorMonthlyDoc/AggregatorMonthlyDoc';
import { Input } from 'reactstrap';
// export
import { saveAs } from 'file-saver';
import * as Comlink from 'comlink';

function ReportAggregatorMonthly({ reportTitle, reportDetails, reportId, setSpinner, report }) {
  const [activeItem, setActiveItem] = useState(report[0]);

  const { height, width } = useReportDimensions();

  const exportFile = async () => {
    setSpinner(true);

    try {
      const worker = new Worker(new URL('../../../../web-workers/pdfWorker.js', import.meta.url));
      const pdfWorker = Comlink.wrap(worker);

      const blob = await pdfWorker.generateAggregatorMonthlyReport(report);

      saveAs(blob, 'aggregator-monthly.zip');
    } catch (err) {
      console.error(err);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        renderSelect={() =>
          report.length > 1 ? (
            <Input
              className="bo-filter bo-w-250"
              type="select"
              value={activeItem.aggregatorId}
              onChange={e =>
                setActiveItem(report.find(x => x?.aggregatorId === Number(e.target.value)))
              }
            >
              {report.map(agg => (
                <option key={agg.aggregatorId} value={agg.aggregatorId}>
                  {agg.aggregatorName}
                </option>
              ))}
            </Input>
          ) : null
        }
        exportFile={exportFile}
      />
      <PDFViewer width={width} height={height} showToolbar={false}>
        <AggregatorMonthlyDoc item={activeItem} />
      </PDFViewer>
    </>
  );
}

export default ReportAggregatorMonthly;
