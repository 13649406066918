import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile } from '../../../../utils/excel';
import ReportTable from '../ReportTable';
import ReportActionRow from '../ReportActionRow';

function ReportConsumerEconomics({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      consumerId: {
        header: 'Id',
        width: 12,
        style: { alignment: { horizontal: 'right' } },
      },
      firstName: {
        header: 'First Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      lastName: {
        header: 'Last Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      billableDays: {
        header: 'Billable Days',
        width: 16,
        style: { alignment: { horizontal: 'right' } },
      },
      periodRevenue: {
        header: 'Revenue',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      activeDays: {
        header: 'Active Days',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      creditDays: {
        header: 'Credit Days',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      creditDaysPercent: {
        header: 'Credit Days (%)',
        width: 18,
        style: { alignment: { horizontal: 'right' }, numFmt: '0.00%' },
      },
      bridgePoints: {
        header: 'BridgePoints',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      bridgePointsPerMonth: {
        header: 'BridgePoints/Month',
        width: 22,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      averageDailyOustrandingCredit: {
        header: 'Average Daily Outstanding Credit',
        width: 30,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      creditCost: {
        header: 'Credit Cost',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      creditAch: {
        header: 'Credit ACH',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      debitAch: {
        header: 'Debit ACH',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      successfulAch: {
        header: 'Successful ACH',
        width: 18,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      failedAch: {
        header: 'Failed ACH',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      achCost: {
        header: 'ACH Cost',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      achFees: {
        header: 'ACH Fees',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      monitoringCost: {
        header: 'Monitoring Cost',
        width: 18,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      estimatedDefaultCost: {
        header: 'Estimated Default Cost',
        width: 22,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      totalCost: {
        header: 'Total Cost',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      totalMargin: {
        header: 'Total Margin',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00;[Red]-"$"#,##0.00' },
      },
    }),
    [],
  );

  const endRow = report.length + 1;

  const excelTotalRow = useMemo(
    () => ({
      consumerId: undefined,
      firstName: undefined,
      lastName: undefined,
      billableDays: undefined,
      periodRevenue: { formula: `SUM(E2:E${endRow})` },
      activeDays: { formula: `AVERAGE(F2:F${endRow})` },
      creditDays: { formula: `AVERAGE(G2:G${endRow})` },
      creditDaysPercent: { formula: `AVERAGE(H2:H${endRow})` },
      bridgePoints: { formula: `AVERAGE(I2:I${endRow})` },
      bridgePointsPerMonth: { formula: `AVERAGE(J2:J${endRow})` },
      averageDailyOustrandingCredit: { formula: `AVERAGE(K2:K${endRow})` },
      creditCost: { formula: `SUM(L2:L${endRow})` },
      creditAch: { formula: `SUM(M2:M${endRow})` },
      debitAch: { formula: `SUM(N2:N${endRow})` },
      successfulAch: { formula: `SUM(O2:O${endRow})` },
      failedAch: { formula: `SUM(P2:P${endRow})` },
      achCost: { formula: `SUM(Q2:Q${endRow})` },
      achFees: { formula: `SUM(R2:R${endRow})` },
      monitoringCost: { formula: `SUM(S2:S${endRow})` },
      estimatedDefaultCost: { formula: `SUM(T2:T${endRow})` },
      totalCost: { formula: `SUM(U2:U${endRow})` },
      totalMargin: { formula: `SUM(V2:V${endRow})` },
    }),
    [endRow],
  );

  const totalRow = useMemo(
    () =>
      Object.entries(excelTotalRow).map(([key, value]) => {
        if (value) {
          const fieldValues = report.map(r => r[key]);

          if (value.formula.includes('SUM(')) {
            return [
              key,
              { formula: value.formula, result: fieldValues.reduce((a, b) => a + b, 0) },
            ];
          }

          if (value.formula.includes('AVERAGE(')) {
            return [
              key,
              {
                formula: value.formula,
                result: fieldValues.reduce((a, b) => a + b, 0) / fieldValues.length,
              },
            ];
          }
        }

        return [key, null];
      }),
    [excelTotalRow, report],
  );

  const exportFile = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Consumer Economics', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;
    worksheet.addRows(report);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };

    const footerRow = worksheet.addRow(totalRow.map(r => r[1]));

    footerRow.font = { bold: true };

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [report, reportFileName, tableColumns, totalRow]);

  const formattedReport = useMemo(() => {
    if (report.length > 0) {
      return [
        ...report.map(r =>
          Object.entries(r).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: applyExcelFormats(value, tableColumns[key]?.style?.numFmt),
            }),
            {},
          ),
        ),
        totalRow.reduce((acc, [key, value]) => ({
          ...acc,
          [key]: applyExcelFormats(value ? value.result : null, tableColumns[key]?.style?.numFmt),
        })),
      ];
    }

    return [];
  }, [report, tableColumns, totalRow]);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportFile={exportFile}
      />
      <ReportTable
        report={formattedReport}
        tableColumns={tableColumns}
        boldTotalRow
        rowKey="consumerId"
      />
    </>
  );
}

export default ReportConsumerEconomics;
