import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile, formatExcelDate } from '../../../../utils/excel';
import ReportActionRow from '../ReportActionRow';
import ReportTable from '../ReportTable';

function ReportAdvancesAnalytics({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      id: {
        header: 'Id',
        width: 12,
        style: { alignment: { horizontal: 'right' } },
      },
      aggregatorName: {
        header: 'Aggregator Name',
        width: 22,
        style: { alignment: { horizontal: 'left' } },
      },
      consumerId: {
        header: 'Consumer Id',
        width: 14,
        style: { alignment: { horizontal: 'right' } },
      },
      consumerFirstName: {
        header: 'First Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      consumerLastName: {
        header: 'Last Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      textualReason: {
        header: 'Reason',
        width: 30,
        style: { alignment: { horizontal: 'left' } },
      },
      status: {
        header: 'Status',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      repaymentMode: {
        header: 'Repayment Mode',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      amount: {
        header: 'Amount',
        width: 18,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      initialAmount: {
        header: 'Initial amount',
        width: 18,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      topupAmount: {
        header: 'Top-ups amount',
        width: 18,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      numberOfTopups: {
        header: 'Number of top-ups',
        width: 20,
        style: { alignment: { horizontal: 'right' } },
      },
      numberOfRepayments: {
        header: 'Number of repayments',
        width: 24,
        style: { alignment: { horizontal: 'right' } },
      },
      numberOfCancelledRepayments: {
        header: 'Number of cancelled repayments',
        width: 30,
        style: { alignment: { horizontal: 'right' } },
      },
      repaymentTrials: {
        header: 'Repayment trials',
        width: 18,
        style: { alignment: { horizontal: 'right' } },
      },
      openTime: {
        header: 'Open time',
        width: 22,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      lastRepaymentDate: {
        header: 'Last Repayment Date',
        width: 23,
        style: { alignment: { horizontal: 'right' }, numFmt: 'yyyy-mm-dd' },
      },
      closeTime: {
        header: 'Close time',
        width: 23,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      usedBridgepoints: {
        header: 'Used Bridgepoints™',
        width: 34,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      remainingBridgepoints: {
        header: 'Remaining Bridgepoints™',
        width: 34,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      maxOfferAmount: {
        header: 'Max Offer Amount',
        width: 21,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      defaultofferAmount: {
        header: 'Default Offer Amount',
        width: 24,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      payrollLimit: {
        header: 'Payroll Limit',
        width: 16,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
      bpLimit: {
        header: 'Bridgepoints™ Limit',
        width: 23,
        style: { alignment: { horizontal: 'right' }, numFmt: '#,##0' },
      },
    }),
    [],
  );

  const restructuredReport = useMemo(() => report.sort((a, b) => a.id - b.id), [report]);

  // TODO: consider placing all the repetitive logic for the reports into one function
  const exportFile = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Advances Analytics', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;

    const dateFormattedReport = restructuredReport.map(r => ({
      ...r,
      openTime: r.openTime ? formatExcelDate(new Date(r.openTime)) : null,
      closeTime: r.closeTime ? formatExcelDate(new Date(r.closeTime)) : null,
    }));

    worksheet.addRows(dateFormattedReport);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [restructuredReport, reportFileName, tableColumns]);

  const formattedReport = useMemo(() => {
    if (restructuredReport.length > 0) {
      return restructuredReport.map(r =>
        Object.entries(r).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]:
              value !== null ? applyExcelFormats(value, tableColumns[key]?.style?.numFmt) : null,
          }),
          {},
        ),
      );
    }

    return [];
  }, [restructuredReport, tableColumns]);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportFile={exportFile}
      />
      <ReportTable report={formattedReport} tableColumns={tableColumns} rowKey="id" />
    </>
  );
}

export default ReportAdvancesAnalytics;
