import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile } from '../../../../utils/excel';
import ReportTable from '../ReportTable';
import ReportActionRow from '../ReportActionRow';

function ReportSignupFunnel({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      aggId: {
        header: 'Aggregator Id',
        width: 16,
        style: { alignment: { horizontal: 'right' } },
      },
      aggName: {
        header: 'Aggregator Name',
        width: 23,
        style: { alignment: { horizontal: 'left' } },
      },
      openSignupLink: {
        header: 'Open Signup Link',
        width: 22,
        style: { alignment: { horizontal: 'right' } },
      },
      consumerCreated: {
        header: 'Consumer Created',
        width: 22,
        style: { alignment: { horizontal: 'right' } },
      },
      accountLinkCompleted: {
        header: 'Bank Account Linked',
        width: 23,
        style: { alignment: { horizontal: 'right' } },
      },
    }),
    [],
  );

  const endRow = report.length + 1;

  const excelTotalRow = useMemo(
    () => ({
      aggId: undefined,
      aggName: undefined,
      openSignupLink: { formula: `SUM(C2:C${endRow})` },
      consumerCreated: { formula: `SUM(D2:D${endRow})` },
      accountLinkCompleted: { formula: `SUM(E2:E${endRow})` },
    }),
    [endRow],
  );

  const totalRow = useMemo(
    () =>
      Object.entries(excelTotalRow).map(([key, value]) => {
        if (value) {
          const fieldValues = report.map(r => r[key]);

          if (value.formula.includes('SUM(')) {
            return [
              key,
              { formula: value.formula, result: fieldValues.reduce((a, b) => a + b, 0) },
            ];
          }
        }

        return [key, null];
      }),
    [excelTotalRow, report],
  );

  const exportFile = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Signup Funnel', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;
    worksheet.addRows(report);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };

    const footerRow = worksheet.addRow(totalRow.map(r => r[1]));

    footerRow.font = { bold: true };

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [report, reportFileName, tableColumns, totalRow]);

  const formattedReport = useMemo(() => {
    if (report.length > 0) {
      return [
        ...report.map(r =>
          Object.entries(r).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: applyExcelFormats(value, tableColumns[key]?.style?.numFmt),
            }),
            {},
          ),
        ),
        totalRow.reduce((acc, [key, value]) => ({
          ...acc,
          [key]: applyExcelFormats(value ? value.result : null, tableColumns[key]?.style?.numFmt),
        })),
      ];
    }

    return [];
  }, [report, tableColumns, totalRow]);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportFile={exportFile}
      />
      <ReportTable
        report={formattedReport}
        tableColumns={tableColumns}
        headerHeight={36}
        boldTotalRow
        rowKey="aggId"
      />
    </>
  );
}

export default ReportSignupFunnel;
