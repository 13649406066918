import { memo, useCallback, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedTable from '../../../../../../components/VirtualizedTable';
import { getTextWidthInOneLine } from '../../../../../../utils/strings';
import { UncontrolledTooltip } from 'reactstrap';

function NewObjectCashflowSubTab({ forecast }) {
  const getTableRows = useCallback(
    direction => {
      const rows = [];

      if (forecast[direction].pending && forecast[direction].pending.length > 0) {
        rows.push(
          {
            uuid: crypto.randomUUID(),
            title: 'Pending Debit',
            titleBold: true,
            direction,
          },
          ...forecast[direction].pending.map(x => ({
            uuid: crypto.randomUUID(),
            title: `- ${x.description}`,
            direction,
            amount: x.amount,
          })),
        );
      }

      if (forecast[direction].recurring && forecast[direction].recurring.length > 0) {
        rows.push(
          {
            uuid: crypto.randomUUID(),
            title: 'Recurring',
            titleBold: true,
            direction,
          },
          ...forecast[direction].recurring.map(x => ({
            uuid: crypto.randomUUID(),
            title: `- ${x.descrption}`,
            direction,
            amount: x.range,
            probability: `${x.probability}%`,
          })),
        );
      }

      if (forecast[direction].funding && forecast[direction].funding.length > 0) {
        rows.push(
          {
            uuid: crypto.randomUUID(),
            title: 'BridgeOver Funding',
            titleBold: true,
            direction,
          },
          ...forecast[direction].funding.map(x => ({
            uuid: crypto.randomUUID(),
            title: `- ${x.descrption}`,
            direction,
            amount: x.amount,
          })),
        );
      }

      if (forecast[direction].payroll) {
        rows.push({
          uuid: crypto.randomUUID(),
          title: 'Payroll',
          titleBold: true,
          direction,
          amount: forecast[direction].payroll,
        });
      }

      if (forecast[direction].variable) {
        rows.push({
          uuid: crypto.randomUUID(),
          title: direction === 'OUTFLOW' ? 'Variable Expenses' : 'Variable Income',
          titleBold: true,
          direction,
          amount: forecast[direction].variable,
        });
      }

      rows.push({
        uuid: crypto.randomUUID(),
        title: direction === 'OUTFLOW' ? 'Total Debit' : 'Total Credit',
        titleBold: true,
        direction,
        amount: forecast[direction].totalRange,
      });

      return rows;
    },
    [forecast],
  );

  const tableColumns = useMemo(
    () => [
      {
        key: 'title',
        header: 'Description',
        width: 300,
        className: 'border-dark',
        renderValue: (value, row) => (
          <div className="text-truncate py-2" id={`forecast_title_${row.uuid}`}>
            {value}
            {getTextWidthInOneLine(value) > 280 && (
              <UncontrolledTooltip
                placement="top"
                target={`forecast_title_${row.uuid}`}
                innerClassName="bo-max-w-400"
                delay={{ show: 200 }}
              >
                {value}
              </UncontrolledTooltip>
            )}
          </div>
        ),
      },
      {
        key: 'amount',
        header: 'Amount',
        width: 100,
        className: 'border-dark',
      },
      {
        key: 'probability',
        header: 'Probability',
        width: 100,
        className: 'border-dark',
      },
    ],
    [],
  );

  const formatCellConditionally = useCallback((row, itemKey) => {
    let style = {};

    if (itemKey === 'probability') {
      style = {
        ...style,
        borderRightWidth: 1,
        borderRightColor: 'black',
        borderRightStyle: 'solid',
      };
    }

    if (row.title.includes('Total')) {
      style = {
        ...style,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopColor: 'black',
        borderBottomColor: 'black',
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        fontWeight: 700,
        backgroundColor: row.direction === 'OUTFLOW' ? '#f6c142' : '#a0cd63',
      };
    }

    if (itemKey === 'title' && row.titleBold) {
      style = {
        ...style,
        fontWeight: 700,
        backgroundColor: row.direction === 'OUTFLOW' ? '#f6c142' : '#a0cd63',
      };
    }

    if (row.direction) {
      style = {
        ...style,
        backgroundColor: row.direction === 'OUTFLOW' ? '#f6c142' : '#a0cd63',
      };
    }

    return style;
  }, []);

  return (
    <AutoSizer>
      {({ height }) => (
        <VirtualizedTable
          tableRows={[...getTableRows('OUTFLOW'), ...getTableRows('INFLOW')]}
          tableColumns={tableColumns}
          headerHeight={24}
          rowHeight={24}
          headerLeftBorder
          width={510}
          height={height}
          extendLastColumn={false}
          customHoverClass="bo-table-alt-hover-bg"
          formatCellConditionally={formatCellConditionally}
          rowKey="uuid"
        />
      )}
    </AutoSizer>
  );
}

export default memo(NewObjectCashflowSubTab);
