import moment from 'moment';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import FullLogo from '../../../../../assets/full_logo.png';
// icons
import LetterIcon from './svgs/LetterIcon';
import CalendarIcon from './svgs/CalendarIcon';
import GlobeIcon from './svgs/GlobeIcon';
// tables
import ActivityTable from './tables/ActivityTable/ActivityTable';
import BillingTable from './tables/BillingTable/BillingTable';
import TurnoverTable from './tables/TurnoverTable/TurnoverTable';
// cards
import EngagementCards from './cards/EngagementCards/EngagementCards';
import TurnoverCards from './cards/TurnoverCards/TurnonverCards';
// charts
import BarAndLineChart from './charts/BarAndLineChart/BarAndLineChart';
// styles
import { styles } from './styles';
import { useMemo } from 'react';

Font.register({
  family: 'Poppins',
  fonts: [
    { src: '/fonts/Poppins/Poppins-Thin.ttf', fontWeight: 100 },
    { src: '/fonts/Poppins/Poppins-ThinItalic.ttf', fontWeight: 100, fontStyle: 'italic' },
    { src: '/fonts/Poppins/Poppins-ExtraLight.ttf', fontWeight: 200 },
    { src: '/fonts/Poppins/Poppins-ExtraLightItalic.ttf', fontWeight: 200, fontStyle: 'italic' },
    { src: '/fonts/Poppins/Poppins-Light.ttf', fontWeight: 300 },
    { src: '/fonts/Poppins/Poppins-LightItalic.ttf', fontWeight: 300, fontStyle: 'italic' },
    { src: '/fonts/Poppins/Poppins-Regular.ttf', fontWeight: 400 },
    { src: '/fonts/Poppins/Poppins-RegularItalic.ttf', fontWeight: 400, fontStyle: 'italic' },
    { src: '/fonts/Poppins/Poppins-Medium.ttf', fontWeight: 500 },
    { src: '/fonts/Poppins/Poppins-MediumItalic.ttf', fontWeight: 500, fontStyle: 'italic' },
    { src: '/fonts/Poppins/Poppins-SemiBold.ttf', fontWeight: 600 },
    { src: '/fonts/Poppins/Poppins-SemiBoldItalic.ttf', fontWeight: 600, fontStyle: 'italic' },
    { src: '/fonts/Poppins/Poppins-Bold.ttf', fontWeight: 700 },
    { src: '/fonts/Poppins/Poppins-BoldItalic.ttf', fontWeight: 700, fontStyle: 'italic' },
    { src: '/fonts/Poppins/Poppins-ExtraBold.ttf', fontWeight: 800 },
    { src: '/fonts/Poppins/Poppins-ExtraBoldItalic.ttf', fontWeight: 800, fontStyle: 'italic' },
    { src: '/fonts/Poppins/Poppins-Black.ttf', fontWeight: 900 },
    { src: '/fonts/Poppins/Poppins-BlackItalic.ttf', fontWeight: 900, fontStyle: 'italic' },
  ],
});

function transformToMonthList(data, dataKey, percentageValue) {
  if (!data || Object.keys(data).length === 0) {
    return [];
  }

  const startDate = moment(
    Object.keys(data).sort((a, b) => moment(a).valueOf() - moment(b).valueOf())[0],
    'YYYY-MM',
  );

  const result = [];

  for (let i = 0; i < 12; i += 1) {
    const currentMonth = startDate.clone().add(i, 'months');
    const yearMonth = currentMonth.format('YYYY-MM');
    const monthLabel = currentMonth.format('MMM');

    const value =
      data[yearMonth] && data[yearMonth][dataKey] !== null && data[yearMonth][dataKey] !== undefined
        ? data[yearMonth][dataKey]
        : null;

    result.push({ label: monthLabel, value: percentageValue ? value * 100 : value });
  }

  return result;
}

function AggregatorMonthlyDoc({ item, title, creator = 'Bridgeover', producer = 'Bridgeover' }) {
  const reportDate = moment({ month: item.month - 1, year: item.year });

  const { billing, activity, engagement, turnover } = item;

  const usersGraphData = transformToMonthList(item.history, 'usersCount');
  const activityAmountGraphData = transformToMonthList(item.history, 'advancesAmount');
  const activityCountGraphData = transformToMonthList(item.history, 'advancesCount');
  const engagementGraphData = transformToMonthList(item.history, 'engagementRate', true);

  const turnoverAsterisks = useMemo(() => {
    let asterisks = ['', '', '', ''];

    let text = [];

    if (!turnover) {
      return ['', '', '', ''];
    }

    if (
      !turnover.non_users ||
      !turnover.non_users.qtdTurnoverAnnualized ||
      !turnover.non_users.ytdTurnoverAnnualized ||
      !turnover.non_users.l12TurnoverAnnualized
    ) {
      asterisks = ['*', '**', '***', '****'];
      text = [
        'Can’t calculate due to missing data',
        turnover.annualTurnoverBenchmerkSource || '',
        turnover.replacementCostSource || '',
        // eslint-disable-next-line max-len
        'Based on the difference between the benchmark turnover and Bridgeover users last 12 months turnover',
      ];
    } else {
      asterisks = ['', '*', '**', ''];
      text = [
        '',
        turnover.annualTurnoverBenchmerkSource || '',
        turnover.replacementCostSource || '',
        '',
      ];
    }

    return { asterisks, text };
  }, [turnover]);

  return (
    <Document title={title} creator={creator} producer={producer}>
      <Page style={styles.page} wrap={false}>
        <View style={styles.topContainer}>
          <Image src={FullLogo} style={styles.logo} />
          <Text style={styles.title}>
            Monthly Report for{' '}
            <Text style={styles.monthYear}>{reportDate.format('MMMM YYYY')}</Text>
          </Text>
          <Text style={styles.customerTitle}>Customer Name</Text>
          <Text style={styles.customerText}>{item.aggregatorName}</Text>
        </View>
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Billing Information</Text>
          <BillingTable
            reportDate={reportDate}
            billableUsers={billing.billableUsers}
            startUsers={billing.startUsers}
            joinedUsers={billing.joinedUsers}
            leftUsers={billing.leftUsers}
            endUsers={billing.endUsers}
            totalAmount={billing.totalAmount}
          />
          <Text style={styles.noteText}>{`* Users who joined or left during ${reportDate.format(
            'MMMM YYYY',
          )} are billed proportionally. See list in the attached file`}</Text>
          {usersGraphData.length > 0 && (
            <>
              <Text style={[styles.sectionTitle, styles.setctionTitleMarginTop]}>Users</Text>
              <BarAndLineChart barData={usersGraphData} />
            </>
          )}
        </View>
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Activity Summary</Text>
          <ActivityTable
            reportDate={reportDate}
            allTimesGrantedBridgepoints={activity.allTimesGrantedBridgepoints}
            bpUsageAllTimes={activity.bpUsageAllTimes}
            bpUsageThisMonth={activity.bpUsageThisMonth}
            bpUsageThisYear={activity.bpUsageThisYear}
            extendedAdvancesAllTimesAmount={activity.extendedAdvancesAllTimesAmount}
            extendedAdvancesAllTimesCount={activity.extendedAdvancesAllTimesCount}
            extendedAdvancesThisMonthAmount={activity.extendedAdvancesThisMonthAmount}
            extendedAdvancesThisMonthCount={activity.extendedAdvancesThisMonthCount}
            extendedAdvancesThisYearAmount={activity.extendedAdvancesThisYearAmount}
            extendedAdvancesThisYearCount={activity.extendedAdvancesThisYearCount}
            thisMonthGrantedBridgepoints={activity.thisMonthGrantedBridgepoints}
            thisYearGrantedBridgepoints={activity.thisYearGrantedBridgepoints}
          />
          {activityAmountGraphData.length > 0 && (
            <BarAndLineChart
              barData={activityAmountGraphData}
              lineData={activityCountGraphData}
              leftYAxisTitle="Amount Extended"
              renderLeftYAxisValue={value =>
                `$${Number(value).toLocaleString('en-US', { maximumFractionDigits: 0 })}`
              }
              rightYAxisTitle="Advances Extended"
            />
          )}
        </View>
        <View style={styles.sectionContainer}>
          <View style={styles.sectionTitleContainer}>
            <Text style={styles.sectionTitle}>Engagement Summary</Text>
            <Text style={styles.sectionSubTitle}>{`As of ${moment(engagement.asOfDate).format(
              'MMMM DD, YYYY',
            )}`}</Text>
          </View>
          <EngagementCards
            employees={engagement.employees}
            engagedUsers={engagement.engagedUsers}
            engagedMonitored={engagement.engagedMonitored}
            engagedFunded={engagement.engagedFunded}
          />
          {engagement.employees && (
            <Text style={styles.noteText}>
              * Based on recent information provided by the company
            </Text>
          )}
          {engagementGraphData.length > 0 && (
            <>
              <Text style={[styles.sectionTitle, styles.setctionTitleMarginTop]}>
                Total Engagement Rate
              </Text>
              <BarAndLineChart
                barData={engagementGraphData}
                renderLeftYAxisValue={value =>
                  `${Number(value).toLocaleString('en-US', { maximumFractionDigits: 0 })}%`
                }
              />
            </>
          )}
        </View>
        {turnover && (
          <View style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Turnover Summary</Text>
            <TurnoverTable
              reportDate={reportDate}
              usersQtdTurnoverAnnualized={turnover.users?.qtdTurnoverAnnualized}
              usersYtdTurnoverAnnualized={turnover.users?.ytdTurnoverAnnualized}
              usersL12TurnoverAnnualized={turnover.users?.l12TurnoverAnnualized}
              nonUsersQtdTurnoverAnnualized={turnover.non_users?.qtdTurnoverAnnualized}
              nonUsersYtdTurnoverAnnualized={turnover.non_users?.ytdTurnoverAnnualized}
              nonUsersL12TurnoverAnnualized={turnover.non_users?.l12TurnoverAnnualized}
            />
            <Text style={styles.noteText}>All Turnover rates are annualized</Text>
            <TurnoverCards
              turnoverAsterisks={turnoverAsterisks}
              annualTurnoverBenchmark={turnover.annualTurnoverBenchmark}
              replacementCost={turnover.replacementCost}
              estimatedAnnualSavings={turnover.estimatedAnnualSavings}
            />
            <View style={styles.turnoverNoteContainer}>
              {turnoverAsterisks.asterisks.map((x, idx) => {
                if (x) {
                  return (
                    <View key={x} style={styles.turnoverNoteRow}>
                      <Text style={styles.turnoverNoteAsterix}>{x}</Text>
                      <Text style={styles.turnoverNoteText}>{turnoverAsterisks.text[idx]}</Text>
                    </View>
                  );
                }

                return null;
              })}
            </View>
          </View>
        )}
        <View style={styles.bottomContainer}>
          <View style={styles.bottomItem}>
            <LetterIcon style={styles.bottomItemIcon} />
            <Text style={styles.bottomItemText}>support@bridgeover.io</Text>
          </View>
          <View style={styles.bottomItem}>
            <CalendarIcon style={styles.bottomItemIcon} />
            <Text style={styles.bottomItemText}>Mon-Fri 9am - 5pm EST</Text>
          </View>
          <View style={styles.bottomItem}>
            <GlobeIcon style={styles.bottomItemIcon} />
            <Text style={styles.bottomItemText}>www.bridgeover.io/employees</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default AggregatorMonthlyDoc;
