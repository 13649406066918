import { memo, useEffect, useRef, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

function TableMultiLineText({ value, showBooleanValues, uniqueId, lineClamp = 3 }) {
  const ref = useRef(null);
  const [textTruncated, setTextTruncated] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const resizeObserver = new ResizeObserver(m =>
        setTextTruncated(m[0].target.scrollHeight > m[0].target.clientHeight),
      );

      resizeObserver.observe(ref.current, { attributes: true });
    }
  }, []);

  const renderValue = () => {
    if (showBooleanValues && typeof value === 'boolean') {
      return value ? 'True' : 'False';
    }

    return value;
  };

  return (
    <div
      ref={ref}
      style={{
        overflow: 'hidden',
        display: '-webkit-box',
        verticalAlign: 'middle',
        WebkitLineClamp: lineClamp,
        lineClamp,
        WebkitBoxOrient: 'vertical',
      }}
      id={uniqueId}
    >
      {renderValue()}
      {textTruncated && (
        <UncontrolledTooltip
          placement="top"
          target={uniqueId}
          innerClassName="bo-max-w-400"
          delay={{ show: 200 }}
        >
          {renderValue()}
        </UncontrolledTooltip>
      )}
    </div>
  );
}

export default memo(TableMultiLineText);
