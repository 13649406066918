import { memo, useCallback, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedTable from '../../../../../../components/VirtualizedTable';
import { faClock, faEnvelopeOpen, faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { faArrowsRotate, faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTextWidthInOneLine } from '../../../../../../utils/strings';
import { UncontrolledTooltip } from 'reactstrap';

const DATES_COLORS = {
  preForecast: 'bg-light border-dark',
  forecast: 'bo-table-row-bg-yellow border-dark',
  postForecast: 'bg-white border-dark',
  postNextPayday: 'bg-light border-dark',
};

const ICONS = {
  pending: faClock,
  recurring: faArrowsRotate,
  funding: faHandHoldingDollar,
  payroll: faEnvelopeOpen,
  variable: faStickyNote,
};

function NewObjectFlowsSubTab({ dates, funding, payroll, pending, recurring, variable }) {
  const tableRows = useMemo(
    () => [
      ...pending.OUTFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'OUTFLOW',
        iconName: 'pending',
        title: x.description,
        type: 'Pending',
        amounts: x.amount,
        [x.date]: { amount: x.amount },
      })),
      ...recurring.OUTFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'OUTFLOW',
        iconName: 'recurring',
        title: x.description,
        type: x.type,
        amounts: x.range,
        ...Object.fromEntries(
          Object.entries(x.dates).map(([key, value]) => [
            key,
            { amount: `${value}%`, status: x.status },
          ]),
        ),
      })),
      ...funding.OUTFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'OUTFLOW',
        iconName: 'funding',
        title: x.description,
        type: 'Advance',
        amounts: x.amount,
        [x.date]: { amount: x.amount },
      })),
      {
        uuid: crypto.randomUUID(),
        direction: 'OUTFLOW',
        iconName: 'variable',
        title: variable.OUTFLOW.description,
        type: '',
        amounts: '',
        ...Object.fromEntries(
          Object.entries(variable.OUTFLOW.dates).map(([key, value]) => [key, { amount: value }]),
        ),
      },
      {
        uuid: crypto.randomUUID(),
        direction: 'INFLOW',
        iconName: 'payroll',
        title: 'Payroll',
        type: '',
        amounts: '',
        ...Object.fromEntries(
          Object.entries(payroll).map(([key, value]) => [
            key,
            { amount: value.amount, status: value.status },
          ]),
        ),
      },
      ...pending.INFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'INFLOW',
        iconName: 'pending',
        title: x.description,
        type: 'Pending',
        amounts: x.amount,
        [x.date]: { amount: x.amount },
      })),
      ...recurring.INFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'INFLOW',
        iconName: 'recurring',
        title: x.description,
        type: x.type,
        amounts: x.range,
        ...Object.fromEntries(
          Object.entries(x.dates).map(([key, value]) => [
            key,
            { amount: `${value}%`, status: x.status },
          ]),
        ),
      })),
      ...funding.INFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'INFLOW',
        iconName: 'funding',
        title: x.description,
        type: 'Advance',
        amounts: x.amount,
        [x.date]: { amount: x.amount },
      })),
      {
        uuid: crypto.randomUUID(),
        direction: 'INFLOW',
        iconName: 'variable',
        title: variable.INFLOW.description,
        type: '',
        amounts: '',
        ...Object.fromEntries(
          Object.entries(variable.INFLOW.dates).map(([key, value]) => [key, { amount: value }]),
        ),
      },
    ],
    [pending, recurring, funding, variable, payroll],
  );

  const frozenColumns = useMemo(
    () => [
      {
        key: 'iconName',
        width: 20,
        renderValue: value => value && <FontAwesomeIcon icon={ICONS[value]} size="sm" />,
      },
      {
        key: 'title',
        width: 240,
        renderValue: (value, row) => (
          <div className="text-truncate py-2" id={`flows_title_${row.uuid}`}>
            {value}
            {getTextWidthInOneLine(value) > 220 && (
              <UncontrolledTooltip
                placement="top"
                target={`flows_title_${row.uuid}`}
                innerClassName="bo-max-w-400"
                delay={{ show: 200 }}
              >
                {value}
              </UncontrolledTooltip>
            )}
          </div>
        ),
      },
      {
        key: 'type',
        width: 100,
      },
      {
        key: 'amounts',
        width: 100,
      },
    ],
    [],
  );

  const tableColumns = useMemo(
    () => [
      ...Object.keys(dates).map(key => ({
        key,
        width: 90,
        renderValue: value => (value ? value.amount : null),
      })),
    ],
    [dates],
  );

  const frozenHeaderConfig = useMemo(
    () => [
      [{ header: '', colspan: 4, className: 'border-dark' }],
      [{ header: '', colspan: 4, className: 'border-dark' }],
      [
        { header: '', colspan: 2, className: 'border-dark' },
        { header: 'Type', colspan: 1, className: 'border-dark' },
        { header: 'Amounts', colspan: 1, className: 'border-dark' },
      ],
    ],
    [],
  );

  const headerConfig = useMemo(() => {
    const payrollCycles = Object.values(dates).reduce((acc, val) => {
      if (acc[val.payrollCycle]) {
        acc[val.payrollCycle] += 1;
      } else {
        acc[val.payrollCycle] = 1;
      }

      return acc;
    }, {});

    return [
      [
        ...Object.entries(dates).map(([key, value]) => ({
          header: key,
          colspan: 1,
          className: DATES_COLORS[value.type],
        })),
      ],
      [
        ...Object.values(dates).map(value => ({
          header: value.weekday,
          colspan: 1,
          className: `${DATES_COLORS[value.type]}`,
        })),
      ],
      [
        ...Object.entries(payrollCycles).map(([key, value]) => ({
          header: key,
          colspan: value,
          className: 'border-dark',
        })),
      ],
    ];
  }, [dates]);

  const firstDatesInForecastPeriod = useMemo(() => {
    const datesInForecastPeriod = [];

    Object.keys(DATES_COLORS).forEach(key => {
      const firstDate = Object.entries(dates).find(([, dValue]) => dValue.type === key);

      if (firstDate) {
        datesInForecastPeriod.push(firstDate[0]);
      }
    });

    datesInForecastPeriod.shift();

    return datesInForecastPeriod;
  }, [dates]);

  const formatCellConditionally = useCallback(
    (row, itemKey, rowIndex) => {
      let style = {};

      if (
        itemKey === 'iconName' ||
        itemKey === 'title' ||
        itemKey === 'type' ||
        itemKey === 'amounts'
      ) {
        style = {
          ...style,
          backgroundColor: row.direction === 'OUTFLOW' ? '#f6c142' : '#a0cd63',
        };
      }

      if (dates[itemKey]) {
        if (dates[itemKey].type === 'preForecast' || dates[itemKey].type === 'postNextPayday') {
          style = { ...style, backgroundColor: '#F8F9FA' };
        }

        if (row[itemKey]?.amount !== null && row[itemKey]?.amount !== undefined) {
          if (row[itemKey]?.status && row[itemKey]?.status !== 'expected') {
            style = {
              ...style,
              backgroundColor: row.direction === 'OUTFLOW' ? '#ffd778' : '#c9f78d',
            };
          } else {
            style = {
              ...style,
              backgroundColor: row.direction === 'OUTFLOW' ? '#f6c142' : '#a0cd63',
            };
          }
        }
      }

      if (
        itemKey === 'type' ||
        itemKey === 'amounts' ||
        firstDatesInForecastPeriod.includes(itemKey)
      ) {
        style = {
          ...style,
          borderLeftColor: 'black',
          borderLeftWidth: 1,
          borderLeftStyle: 'solid',
        };
      }

      if (itemKey === 'amounts' || itemKey === Object.keys(dates)[Object.keys(dates).length - 1]) {
        style = {
          ...style,
          borderRightColor: 'black',
          borderRightWidth: 1,
          borderRightStyle: 'solid',
        };
      }

      if (rowIndex === tableRows.length - 1) {
        style = {
          ...style,
          borderBottomColor: 'black',
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
        };
      }

      return style;
    },
    [firstDatesInForecastPeriod, dates, tableRows],
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VirtualizedTable
          tableRows={tableRows}
          frozenColumns={frozenColumns}
          frozenHeaderConfig={frozenHeaderConfig}
          tableColumns={tableColumns}
          headerConfig={headerConfig}
          headerHeight={24}
          rowHeight={24}
          headerLeftBorder
          extendLastColumn={false}
          customHoverClass="bo-table-alt-hover-bg"
          cellPaddingLeftSize={2}
          width={width}
          height={height}
          formatCellConditionally={formatCellConditionally}
          rowKey="uuid"
        />
      )}
    </AutoSizer>
  );
}

export default memo(NewObjectFlowsSubTab);
