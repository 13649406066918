import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile, formatExcelDate } from '../../../../utils/excel';
import ReportActionRow from '../ReportActionRow';
import ReportTable from '../ReportTable';

function ReportConsumerHubSpot({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      hubspotCompanyRecordId: {
        header: 'Company Record ID',
        width: 22,
        style: { alignment: { horizontal: 'right' } },
      },
      id: {
        header: 'Consumer ID',
        width: 16,
        style: { alignment: { horizontal: 'right' } },
      },
      firstName: {
        header: 'First Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      lastName: {
        header: 'Last Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      email: {
        header: 'Email',
        width: 36,
        style: { alignment: { horizontal: 'left' } },
      },
      phone: {
        header: 'Phone',
        width: 20,
        style: { alignment: { horizontal: 'right' } },
      },
      streetAddress: {
        header: 'Street Address',
        width: 30,
        style: { alignment: { horizontal: 'left' } },
      },
      streetAddress2: {
        header: 'Street Address 2',
        width: 30,
        style: { alignment: { horizontal: 'left' } },
      },
      city: {
        header: 'City',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      state: {
        header: 'State',
        width: 10,
        style: { alignment: { horizontal: 'left' } },
      },
      zip: {
        header: 'Postal Code',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      country: {
        header: 'Country',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      employeeId: {
        header: 'Employee ID',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      dateOfBirth: {
        header: 'Date of Birth',
        width: 18,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd' },
      },
      last4ssn: {
        header: 'Last 4 digits of SSN',
        width: 22,
        style: { alignment: { horizontal: 'right' } },
      },
      registrationDate: {
        header: 'Registration Date',
        width: 20,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd' },
      },
      status: {
        header: 'Status',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      bankName: {
        header: 'Bank Name',
        width: 30,
        style: { alignment: { horizontal: 'left' } },
      },
      bankAccountStatus: {
        header: 'Bank Account Status',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      bankCategory: {
        header: 'Bank Category',
        width: 18,
        style: { alignment: { horizontal: 'left' } },
      },
      contactOwner: {
        header: 'Contact Owner',
        width: 36,
        style: { alignment: { horizontal: 'left' } },
      },
      lifecycleStage: {
        header: 'Lifecycle Stage',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      b2cType: {
        header: 'B2C Type',
        width: 18,
        style: { alignment: { horizontal: 'left' } },
      },
      marketingContactStatus: {
        header: 'Marketing Contact Status',
        width: 26,
        style: { alignment: { horizontal: 'left' } },
      },
    }),
    [],
  );

  const exportFile = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Consumer HubSpot', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;

    const dateFormattedReport = report.map(r => ({
      ...r,
      dateOfBirth: r.dateOfBirth ? formatExcelDate(new Date(r.dateOfBirth)) : null,
      registrationDate: r.registrationDate ? formatExcelDate(new Date(r.registrationDate)) : null,
    }));

    worksheet.addRows(dateFormattedReport);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [report, reportFileName, tableColumns]);

  const formattedReport = useMemo(() => {
    if (report.length > 0) {
      return report.map(r =>
        Object.entries(r).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]:
              value !== null ? applyExcelFormats(value, tableColumns[key]?.style?.numFmt) : null,
          }),
          {},
        ),
      );
    }

    return [];
  }, [report, tableColumns]);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportFile={exportFile}
      />
      <ReportTable
        report={formattedReport}
        tableColumns={tableColumns}
        headerHeight={36}
        rowKey="id"
      />
    </>
  );
}

export default ReportConsumerHubSpot;
