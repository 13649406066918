import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile, formatExcelDate } from '../../../../utils/excel';
import ReportActionRow from '../ReportActionRow';
import ReportTable from '../ReportTable';

function ReportTransfersAnalytics({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      id: {
        header: 'Id',
        width: 12,
        style: { alignment: { horizontal: 'right' } },
      },
      fundingId: {
        header: 'Advance Id',
        width: 14,
        style: { alignment: { horizontal: 'right' } },
      },
      advanceRepaymentMode: {
        header: 'Advance Repayment Mode',
        width: 28,
        style: { alignment: { horizontal: 'left' } },
      },
      aggregatorName: {
        header: 'Aggregator Name',
        width: 22,
        style: { alignment: { horizontal: 'left' } },
      },
      consumerId: {
        header: 'Consumer Id',
        width: 15,
        style: { alignment: { horizontal: 'right' } },
      },
      consumerFirstName: {
        header: 'First Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      consumerLastName: {
        header: 'Last Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      direction: {
        header: 'Direction',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      amount: {
        header: 'Amount',
        width: 18,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      fee: {
        header: 'Fee',
        width: 18,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      status: {
        header: 'Status',
        width: 18,
        style: { alignment: { horizontal: 'left' } },
      },
      failureReason: {
        header: 'Failure Reason',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      provider: {
        header: 'Provider',
        width: 18,
        style: { alignment: { horizontal: 'left' } },
      },
      type: {
        header: 'Type',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      reconciled: {
        header: 'Reconciled',
        width: 18,
        style: { alignment: { horizontal: 'left' } },
      },
      createTime: {
        header: 'Create Time',
        width: 22,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      confirmTime: {
        header: 'Confirm Time',
        width: 22,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      failTime: {
        header: 'Fail Time',
        width: 22,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      user: {
        header: 'User',
        width: 36,
        style: { alignment: { horizontal: 'left' } },
      },
    }),
    [],
  );

  const restructuredReport = useMemo(
    () => report.sort((a, b) => new Date(a.createTime) - new Date(b.createTime)),
    [report],
  );

  const exportFile = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Transfer Analytics', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;

    const dateFormattedReport = restructuredReport.map(r => ({
      ...r,
      createTime: r.createTime ? formatExcelDate(new Date(r.createTime)) : null,
      confirmTime: r.confirmTime ? formatExcelDate(new Date(r.confirmTime)) : null,
      failTime: r.failTime ? formatExcelDate(new Date(r.failTime)) : null,
      reconciled: r.reconciled ? 'Yes' : 'No',
    }));

    worksheet.addRows(dateFormattedReport);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [restructuredReport, reportFileName, tableColumns]);

  const formattedReport = useMemo(() => {
    if (restructuredReport.length > 0) {
      return restructuredReport.map(r =>
        Object.entries(r).reduce((acc, [key, value]) => {
          if (key === 'reconciled') {
            return {
              ...acc,
              [key]: value ? 'Yes' : 'No',
            };
          }

          return {
            ...acc,
            [key]:
              value !== null ? applyExcelFormats(value, tableColumns[key]?.style?.numFmt) : null,
          };
        }, {}),
      );
    }

    return [];
  }, [restructuredReport, tableColumns]);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportFile={exportFile}
      />
      <ReportTable
        report={formattedReport}
        tableColumns={tableColumns}
        headerHeight={36}
        rowKey="id"
      />
    </>
  );
}

export default ReportTransfersAnalytics;
