import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function AggregatorRewardsForm({ aggregatorDisableRewards, changeChecked }) {
  return (
    <Col md={6} className="bo-same-line">
      <div>
        <Input
          type="checkbox"
          name="disableRewards"
          id="disableRewards"
          checked={aggregatorDisableRewards || false}
          onChange={changeChecked}
        />
        <Label className="p-0 m-0">Disable Rewards</Label>
      </div>
    </Col>
  );
}

export default memo(AggregatorRewardsForm);
